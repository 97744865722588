.heroNewbuildingPara {
  color: rgba(255, 255, 255, 1);

  font-family: Poppins;
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
}

.newbuildingELE {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.newbuildingHeading {
  font-family: Playfair Display;
  font-size: 52px;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

@media (max-width: 767px) {
  .newbuildingHeading {
    font-size: 25px;
    line-height: normal;
    text-align: center;
  }
  .heroNewbuildingPara {
    line-height: normal;
    font-size: small;
    width: 100% !important;
  }
  .newbuildingELE {
    gap: 1rem;
  }
}
