.heroContactContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.heroContactUS {
  width: 100%;
  height: auto;
}
.contactHeading {
  position: absolute;
  font-family: Playfair Display;
  font-size: 82px;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.workingContainer {
  display: flex;
  justify-content: center;
  padding: 6rem;
  gap: 6rem;
}

.workingChild1 {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}
.workingChild2 {
  display: flex;
  flex-direction: column;
}
.workingText {
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
}
.timeText {
  font-family: "Poppins";
  font-size: 20px;

  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.inputBoxFormContainer {
  width: 749px;
  min-height: 516px;
  background-color: rgba(249, 249, 249, 1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  gap: 1rem;
}
.inputB {
  display: flex;
  gap: 1rem;
}
.getInTouch {
  font-family: Playfair Display;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}
input {
  width: 324.3px;
  height: 60px;
  padding: 12px;

  border-radius: 1px;
  border: 1px solid rgba(238, 238, 238, 1);
}
.inputL {
  width: 665px;
  height: 133px;
  border-radius: 1px;
  border: 1px solid rgba(238, 238, 238, 1);
  resize: none;
  padding: 12px;
}

::placeholder {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(161, 161, 161, 1);
}
.ContactContainerMap {
  display: flex;
  justify-content: center;
  padding: 5rem;
}
.ContactContainerMapChild {
  display: flex;
  width: 1220px;
  height: 440px;
}
.ContactCard {
  display: flex;
  flex-direction: column;
  background-color: rgba(43, 48, 106, 1);
  border-radius: 16px 0px 0px 16px;
  padding: 1rem;
  justify-content: space-evenly;
}

.mapLocation {
  width: 100%;
  height: 100%;
}
.c1 {
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.c2 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(221, 221, 221, 1);
  margin: 0px;
}
.c3 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(183, 186, 216, 1);
  margin: 0px;
}
.c4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(221, 221, 221, 1);
  margin: 0px;
}
.c5 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.c5 a {
  text-decoration: none;
  color: white;
}
.locationImg {
  border-radius: 0px 16px 16px 0px;
}
@media (max-width: 767px) {
  .heroContactUS {
    object-fit: cover;
    height: 213px;
  }
  .inputB {
    flex-direction: column;
  }
  .inputBoxFormContainer {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .workingContainer {
    flex-direction: column;
  }
  .workingContainer {
    padding: 0px;
  }
  .workingChild1 {
    padding: 2rem;
  }
  .inputL {
    width: 100%;
  }
  .ContactContainerMap {
    padding-bottom: 10rem;
  }
  .ContactContainerMapChild {
    flex-direction: column;
    gap: 1rem;
  }
  .locationImg {
    height: 100%;
  }
  .ContactCard {
    border-radius: 16px;
  }
  .locationImg {
    border-radius: 16px;
    height: 250px;
  }
  .contactHeading {
    font-size: 40px;
  }
}
