.staffLargeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 275px;
}

.staffLargefInfo {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.staffLargeInfoTitle {
  font-size: 24px;
  text-align: center;
}

.designationLargeInfo {
  color: #4a4a4a;
  font-size: 16px;
}

.staffSmallContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin: 10px 30px;
}

.profileSmallImgDiv {
  border-radius: 50%;
  width: 150px;
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
.staffSmallInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.staffSmallInfoTitle {
  font-size: 20px;
}

.designationSmallInfo {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  text-align: center;
}
