@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Playfair+Display:wght@500;800&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");
.glaneDbtrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.glanceMainBox {
  display: flex;
}
.glanceImg1 {
  position: relative;
}

.imgHomeBannerContainer {
  width: 702.842px;
  height: 577.141px;
  flex-shrink: 0;
}
.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.glanceText1 {
  position: absolute;
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
  bottom: 25px;
  right: 65px;
}
.glanceImg2 {
  position: relative;
  max-width: 734px;
  min-width: 400px;
  width: 100%;
}
.glanceText2 {
  position: absolute;
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
  bottom: 25px;
  left: 20px;
}
.glanceImg3 {
  position: relative;
}
.glanceText3 {
  position: absolute;
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
  bottom: 25px;
  left: 20px;
}
.glanceMainParent {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.glanceMainChild {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.glanceMainGrandChild {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.boxGChild {
  display: flex;
  justify-content: space-around;
}

.carouselItemHero {
  display: flex;
  gap: 3.5rem;
  height: 650px;
}
.carousel-indicators li {
  display: none;
}
.contentHero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35rem;
}

.glanceContainer {
  position: relative;
  top: -1400px;
  margin-left: -75px;
}

.containerAlumni {
  height: 744px;
  background-color: rgba(218, 242, 255, 1);
  width: 1440px;
}

.Alumniheading {
  width: 100%;
  height: 72px;

  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  padding-bottom: 5rem;
  padding-top: 2rem;
}

.statements {
  display: flex;
  justify-content: center;
  top: 2331.5px;
  left: 300.05px;
  gap: 5rem;
}
.statementsMob {
  display: none;
  justify-content: center;

  gap: 5rem;
}
.childFlex {
  display: flex;
  flex-direction: column;
  height: 459px;
  top: 2263.5px;
  left: 245.0517578125px;
  width: 262px;
  align-items: center;
}
.AlumniName {
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 12px;
}

.AlumniHeading {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 12px;
  width: 100% !important;
  text-align: center !important;
}
.AlumniPara {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.AlumniImg {
  padding-bottom: 12px;
}
.Heartbtn {
  background-color: rgba(245, 78, 57, 1);
  color: white;
  border-radius: 4px;
  width: 183px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  transition: transform 0.9s ease, animation-delay 0.9s ease;
}

.Csrbtn {
  color: rgba(245, 78, 57, 1);
  border: 1.4px solid rgba(245, 78, 57, 1);
  border-radius: 4px;
  width: 154px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  transition: transform 0.9s ease, animation-delay 0.9s ease;
}

.Heartbtn:hover {
  transform: scale(1.1);
  animation-delay: 3s;
}

.Csrbtn:hover {
  transform: scale(1.1);
  animation-delay: 3s;
}

.footer {
  border: 1px solid rgba(234, 234, 234, 1);
  height: auto;
  width: 100%;
  padding: 4rem calc(50vw - 35rem) 2.5rem calc(50vw - 35rem);
  background-color: rgba(245, 245, 245, 1);
}
.footerContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  .school {
    display: flex;
    gap: 2rem;
  }
}
.footerEle {
  display: flex;
  flex-direction: column;
}
.footerSecondryEle {
  display: flex;
  justify-content: space-between;
}

.dbtrinfo {
  width: 206px;
  height: 50px;

  font-family: Playfair Display;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.Virtuousness {
  font-family: "Poppins", sans-serif;

  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.1em;
  text-align: left;
  color: rgba(35, 44, 149, 1);
}

.established {
  font-family: "Poppins", sans-serif;

  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: (64, 64, 64, 1);
  width: 193px;
  height: 75px;
  top: 191px;
  left: 305px;
}

.quicklinks {
  font-family: "Poppins", sans-serif;

  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.quicklinksSec {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  width: 173px;
  cursor: pointer;
}

.quicklinksSecPhone {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  width: 173px;
}
.quicklinksSecPhone a {
  text-decoration: none; /* Remove underline for links */
  color: #000; /* Set the text color for the link */
}
.quicklinksSec a {
  color: #000;
}
.quicklinksSecAddress {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  width: 173px;
  cursor: pointer;
}
.quicklinksSecAddress a {
  color: #000;
  text-decoration: none;
}

.dbtrNH {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.Big {
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  width: 192px;
  height: 54px;
  margin-bottom: 22px;
}

.footerSecondry {
  border-top: 1px solid rgba(204, 204, 204, 0.3);
  padding-top: 24px;
  font-weight: 300;
}
.logoDb {
  width: 118px;
  height: 138px;
}

.Design {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  width: 381px;
  height: 36px;
  width: 100%;
}
.logoContainer {
  display: flex;
  gap: 22px;
}

.bfooter {
  height: 62px !important;

  background-color: rgba(245, 78, 57, 1);
  color: white;
  cursor: pointer;

  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0em;
  opacity: 0.9;
  line-height: 60px;
  text-align: center;
  ///
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.5rem;
  border: 2px solid #f54e39;

  font-weight: 500;
  font-size: 20px;
}

.bfooter:hover {
  opacity: 1.5;
}
.container {
  height: 850px;
  position: relative;
}

.heading {
  display: flex;
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  left: 50%;
  width: 100%;
  justify-content: center;
  margin-top: 38px;
}

.Img5 {
  max-width: 734px;
  min-width: 400px;
  width: 100%;
  height: 249px;
}

.boxG {
  overflow: hidden;

  width: 352px;
  height: 332px;
  top: 1470px;
  left: 544.0517578125px;
  border-radius: 16px;
  background-color: rgba(218, 242, 255, 1);
}

.Img3:hover {
  filter: brightness(80%);
}
.Img4:hover {
  filter: brightness(80%);
}
.Img5:hover {
  filter: brightness(80%);
}
.Img1:hover {
  filter: brightness(80%);
}
.Img2:hover {
  filter: brightness(80%);
}
.talent {
  position: absolute;

  height: 32px;
  top: 2019px;
  left: 960.0517578125px;
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.assoc {
  position: absolute;

  height: 32px;
  top: 1737.704345703125px;
  left: 192.677734375px;
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.parent {
  position: absolute;

  height: 32px;
  top: 2019px;
  left: 192.05px;
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.boxGHead {
  height: 37px;
  padding: 33px;

  font-family: Playfair Display;
  font-size: 28px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;
}
.boxGPara {
  padding: 33px;

  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  height: 12rem;
}

.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  height: 123px;
  justify-content: space-around;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  margin: -5px;
  padding: 10px;
  gap: 0px;
  width: 1440px;
}
.heroConatinerHome {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 80px 20px 80px;
  width: 100%;
}
.cImg {
  width: 596px;
  height: auto;
}
.future {
  font-family: Playfair Display;
  font-size: 82px;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: 0em;
  text-align: left;
}
.paraHeroSection {
  top: 250px;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}
.btn2 {
  position: absolute;
  left: 700px;
  top: 400px;
  width: 212px;
  background-color: rgba(245, 78, 57, 1);
  color: white;
  width: 183px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  border-radius: 6px;
  letter-spacing: 0em;
}
.btn2:hover {
  opacity: 0.6;
}
.ImageHero {
  width: 1439px;
  height: 459px;
  position: absolute;
  top: 2854.5px;
  left: 1.0517578125px;
}

.p1 {
  position: relative;
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: 0em;
  text-align: center;
  width: 952px;
  height: 83px;
  color: rgba(255, 255, 255, 1);
}

.p2 {
  position: relative;
  width: 902px;
  height: 96px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(221, 221, 221, 1);
}

.b1 {
  position: relative;
  padding-left: 44px;
  padding-right: 44px;
  height: 60px;

  border-radius: 4px;
  background-color: rgba(245, 78, 57, 1);
  color: white;
  cursor: pointer;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  border-radius: 6px;
  letter-spacing: 0em;
  opacity: 0.9;
  line-height: 60px;
  text-align: center;
}

.b1:hover {
  opacity: 1.5;
}

.hero2container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: 459px;
    position: relative;
  }
}
.heroItemsContainer {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
}
.history {
  display: flex;
  background-color: rgba(43, 48, 106, 1);
  gap: 7rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  img {
    width: 345px;
    height: 200px;
  }
}

.box1Container {
  display: flex;
  /* width: 1px; */
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 3rem;
  max-width: 694px;
}
.box1 {
  width: 210px;
  height: 142px;
  top: 868px;
  left: 586.0517578125px;
  border-radius: 16px;
  background-color: rgba(60, 65, 125, 1);
  color: rgba(255, 255, 255, 1);
  padding-top: 12px;
}
.textBox {
  padding: 16px;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  /* width: 69px; */
  height: 42px;
  top: 900px;
  left: 610.0517578125px;
}

.innerText {
  font-family: Poppins;
  margin-top: 12px;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  width: 146px;
}

.historyContainer {
  padding: 5rem;

  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  background-color: rgba(43, 48, 106, 1);
}
.containerBul {
  position: absolute;
  height: 373px;
  top: 3423.5px;
  width: 386px;
  height: 72px;
  top: 3423.5px;
  left: 160.0517578125px;
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
}

.paraNewBulding {
  position: absolute;
  font-family: "Poppins", sans-serif;

  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: 363px;
  height: 120px;
  top: 3537.5px;
  left: 160.0517578125px;
}
.Img2 {
  width: 353px;
  height: 613px;
}
.Img1 {
  width: 353px;
  height: 332px;
}
.VidClass {
  position: absolute;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.12);
  width: 614px;
  height: 411px;
  top: 3385.5px;
  left: 666.0517578125px;
  border-radius: 16px;
}
.newBuildingContainer {
  display: flex;
  padding: 5rem;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.newBuildingChild {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.paraBuilding {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  width: 363px;
}

//  .carousel-indicators .active{
//   background: red;
//  }
.carousel-indicators button {
  background: rgba(52, 129, 232, 1) !important;
  border-radius: 100%;
}
.carousel-indicators [data-bs-target] {
  height: 12px !important;
  width: 12px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}
.innerHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 114px;
}
.mediaNewbuilding {
  width: 614px;
  height: 411px;
  margin-bottom: 0px !important;
  img {
    width: 614px;
    height: 411px;
    border-radius: 1rem;
    box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.12);
  }
}
.socialIcon {
  opacity: 0.4;
}
.downloadIcon {
  text-decoration: none;
  width: 17.5rem;
  height: 3.75rem;
  border-radius: 0.25rem;
  background: #f54e39;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  cursor: pointer;
}

@media (max-width: 767px) {
  .carouselItemHero {
    width: 95vw;
    flex-direction: column;
  }
  .footerSecondryEle {
    flex-direction: column;
  }
  .Design {
    padding-bottom: 0px;
  }
  .heroConatinerHome {
  }
  .newBuildingContainer {
    flex-direction: column;
  }

  .imgHomeBannerContainer {
    width: 100%;
    height: 343px;
    flex-shrink: 0;
  }
  .paraHeroSection {
    font-size: x-large;
  }
  .future {
    font-size: 28px;
    line-height: normal;
  }
  .carouselItemHero {
    gap: 0px;
  }
  .bannerImg {
    width: 100%;
    height: 290px;
    object-fit: cover;
  }
  .mediaNewbuilding {
    width: 95vw;
  }
  .p1 {
    font-size: 30px;
    width: 95vw;
    line-height: normal;
  }
  .p2 {
    width: 95vw;

    line-height: 31px;
    font-size: 23px;
  }
  .b1 {
    width: 95vw;
  }

  .hero2container {
    flex-direction: column;
  }

  .history {
    flex-direction: column;
    width: 95vw;
    gap: 0;
    img {
      width: 95vw;
    }
  }
  .box1 {
    width: 95vw;
  }

  .btn2 {
    position: static;
    padding-left: 0.5rem;
  }

  .glanceContainer {
    position: relative;
    top: -2350px;
    margin-left: -10px;
  }
  .assoc {
    top: 2660px;
    left: 0px;
    padding-left: 0.5rem;
  }
  .boxG {
    left: 0px;
    top: 2710px;
    margin-left: 0.5rem;
    width: 95vw;
  }
  .Img4 {
    left: 0px;
    top: 3050px;
    padding-left: 0.5rem;
    width: 95vw;
    img {
      width: 95vw;
    }

    img {
      width: 95vw;
    }
  }
  .parent {
    top: 3135px;
    left: 0px;
    padding-left: 0.5rem;
  }
  .Img3 {
    left: 0px;
    top: 3182px;
    width: 95vw;
    padding-left: 0.5rem;
    img {
      width: 95vw;
    }
  }
  .talent {
    top: 3755px;
    left: 0px;
    padding-left: 0.5rem;
  }
  .hero2container {
    display: flex;

    .ImageHero {
      top: 4554.5px;
      width: 97vw;
      object-fit: cover;
      padding-left: 0.5rem;
      height: 55%;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    border: none;
    height: auto;
    gap: 1rem;
  }
  .footerMob {
    flex-direction: column;
  }
  .footerSecondry {
    background-color: rgb(245, 245, 245);
  }
  .hero {
    width: 95vw;
  }
  .container {
    height: 1650px;
    margin-bottom: 17rem;
  }
  .heading {
    padding: 1rem;
  }
  .glanceMainBox {
    flex-direction: column;
    width: 100%;
  }
  .glanceMainGrandChild {
    flex-direction: column;
    width: 100%;
  }
  .glanceMainChild {
    flex-direction: column;
    width: 100%;
  }
  .glanceMainParent {
    flex-direction: column;
    width: 100%;
  }

  .glanceImg1 {
    img {
      width: 100%;
      padding: 0.5rem;
    }
  }
  .glanceImg2 {
    img {
      width: 100%;
      padding: 0.5rem;
    }
  }
  .glanceImg3 {
    img {
      width: 100%;
      padding: 0.5rem;
    }
  }
  .carousel-indicators button {
    display: none !important;
  }
  .carousel-indicators [data-bs-target] {
    display: none !important;
  }
  .footerContainer {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  .footerSecondry {
    padding: 1rem;
  }
  .logoContainer {
    gap: 12px;
  }
  .box1Container {
    width: 100%;
  }
}
