@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");

.heroSchoolContainer {
  width: 100%;
  height: 442px;
  display: flex;
}

.heroSchoolImg1 {
  position: absolute;
  width: 100%;
  height: 442px;
}

.heroSchoolImg2 {
  position: absolute;
  width: 100%;
  height: 442px;
}

.textHeadingContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.heroText {
  display: flex;
  width: 700px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  font-family: Playfair Display;
  font-size: 82px;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: 0em;
  height: 190px;
  top: 249px;
  color: rgba(255, 255, 255, 1);
}

.HeritageContainer {
  width: 100%;
  display: flex;
  gap: 5rem;
  padding: 9rem;
  justify-content: center;
  align-items: center;
  img {
    width: 543px;
    height: 373px;
  }
}
.maintextContainer {
  display: flex;
  flex-direction: column;
  width: 503px;
}

.Maintext {
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 2rem;
  p {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 2rem;
  }
}

.VisionConatiner {
  justify-content: center;
  gap: 9rem;
  display: flex;
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  padding: 5rem;
}

.VisionHeading {
  font-family: Playfair Display;
  font-size: 42px;
  font-weight: 600;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
}

.VisionPara {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  width: 486px;
}

.SchoolHistory {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 4rem;
  padding: 9rem calc(50vw - 35rem);

  img {
    width: 623px;
    height: 402px;
  }
}

.ParaText {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: 435px;
  padding-bottom: 1rem;
}

.btnSchool {
  width: 212px;
  background-color: rgba(245, 78, 57, 1);
  color: white;
  width: 183px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  border-radius: 6px;
  letter-spacing: 0em;
}
.btnSchool:hover {
  opacity: 0.6;
}

.TrusteesContainer {
  padding-top: 4rem;
  padding-bottom: 4rem;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: rgba(218, 242, 255, 1);
}

.MembersContainer {
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  gap: 12px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.MembersCard {
  padding: 1rem;
  background-color: white;
  border-radius: 16px;
  width: 266px;
  height: 99px;
}
.MembersCardStaff {
  padding: 1rem;
  background-color: rgba(218, 242, 255, 1);
  border-radius: 16px;
  width: 266px;
  height: 99px;
}

.boxheadtext {
  font-family: Playfair Display;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.boxParaText {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.textT {
  width: 930px;
  text-align: center;
}

@media (max-width: 767px) {
  /* Styles for mobile devices go here */
  .HeritageContainer {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0.5rem;
    gap: 0.5rem;
    img {
      width: 95vw;
    }
  }

  .VisionConatiner {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0.5rem;
    gap: 0.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .VisionPara {
    width: 100%;
  }
  .SchoolHistory {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0.5rem;
    gap: 0.5rem;
    img {
      width: 95vw;
      height: auto;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .Maintext {
    width: 95vw;
    text-align: center;
    padding-top: 2rem;
  }
  .MembersContainer {
    flex-direction: column;
    width: 95vw;
    align-items: center;
  }
  .ParaText {
    width: 95vw;
  }
  .heroSchoolImg1 {
    width: 98vw;
    padding-left: 0.5rem;
  }
  .heroSchoolImg2 {
    width: 98vw;
    padding-left: 0.5rem;
  }
  .heroText {
    position: absolute;
    max-width: fit-content;
    font-family: Playfair Display;
    font-size: 60px;
    font-weight: 700;
    line-height: 95px;
    text-align: center;
    width: 921px;
    height: 190px;
    top: 200px;
    left: 0px;
    color: rgba(255, 255, 255, 1);
  }
  .textT {
    width: 95vw;
    text-align: center;
  }
  .maintextContainer {
    width: 100%;
  }
}

/* Media query for tablets with a minimum width of 768px and a maximum width of 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Styles for tablets go here */
}
