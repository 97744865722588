label {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(245, 78, 57, 1);

  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  width: 243px;

  line-height: 60px;
}
