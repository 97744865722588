@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Playfair+Display:wght@500;800&family=Poppins&family=Source+Sans+3:wght@300;700&display=swap");

.AlumniTestImg {
  height: 117px;
  width: 117px;
  z-index: 1;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: lightgray;
  border-radius: 50%;
}

.AssocHeroContainer {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
  }
}
.cardButton {
  padding-top: 8rem;
  padding-left: 1rem;
}
.AssociationHeroHeading {
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  position: absolute;
  color: white;
  top: 40%;
  width: 100%;
  text-align: center;
  left: 0px;
}
.AssociationHeroPara {
  font-family: "Poppins", sans-serif;
  line-height: 72px;
  position: absolute;
  color: white;
  top: 60%;
  width: 100%;
  text-align: center;
}
.TopTextContainer {
  display: flex;
  justify-content: center;
  padding: 6rem calc(50vw - 35rem);
  gap: 4.5rem;
  align-items: center;
}

.line {
  width: 12px;
  height: 150px;
  background-color: rgba(236, 236, 236, 1);
  margin-right: 12px;
}
.TopTextPara1 {
  color: rgba(74, 74, 74, 1);
  font-size: 20;
  font-family: "Poppins";
  font-weight: 300;
  word-wrap: break-word;
}
.TopTextPara2 {
  font-size: 24;
  font-family: Playfair Display;
  font-weight: 600;
  word-wrap: break-word;
}

.CalenderContainer {
  display: flex;
  background: #f9f9f9;
  flex-direction: column;
  padding-bottom: 3rem;
}

.HeadingCalender {
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.eventsMarginCard {
  display: flex;
  margin: 10px 10px 30px 10px;
  gap: 2.25rem;
}

.CalenderCard {
  display: flex;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 32px 25px rgb(0 0 0 / 6%);
  .CalenderCardImage {
    width: 45%;
  }
  .CalenderCardImage img {
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}
.CardConatinerCalender {
  display: flex;
  justify-content: center;
  padding: 2rem calc(50vw - 35rem);
  flex-direction: column;
  gap: 2.5rem;
}
.CalenderCardText {
  padding: 4rem;
}
.CalenderCardText2 {
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1rem;
  padding-top: 1rem;
}
.CalenderCardText3 {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 1rem;
  margin-left: 1rem;
}
.CalenderCard1box {
  margin-left: 1rem;
  margin-top: 2rem;
  width: fit-content;
  border-radius: 6px;
  background: #98c5ff;
}
.TimeAndLocation {
  padding-left: 1rem;
  padding-top: 1rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PastEventsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}
.SupportContainer {
  padding: 5rem 10rem;
  display: flex;
  justify-content: center;
  background: #f9f9f9;
  gap: 2rem;
  padding: 5rem calc(50vw - 35rem);
  align-items: center;
}
.SuppImg {
  width: 440.299px;
  height: 425px;
  flex-shrink: 0;
}
.SupportSideElem {
  display: flex;
  flex-direction: column;
}
.SuppHeading {
  color: #404040;
  font-family: Playfair Display;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.SuppPara {
  color: #404040;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AlumniFrameContainer {
  display: flex;
  justify-content: space-between;
  padding: 5rem calc(50vw - 35rem);
  gap: 7rem;
}
.AlumniFrameElements {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.AlumniParaFrame {
  color: #404040;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.FrameImg {
  width: 589.17px;
  height: 405px;
  flex-shrink: 0;
}
.GroupContainer {
  background: #daf2ff;
  display: flex;
  padding: 5rem calc(50vw - 35rem);
  gap: 12rem;
  align-items: center;
  justify-content: center;
}
.GrpHeading {
  color: #191919;
  font-family: Playfair Display;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.GrpImg {
  width: 400px;
}
.AlumniSectionConatianer {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 5rem;
  align-items: center;
}
.AlumniTest {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hoverBox {
  position: absolute;
  width: 320px;
  height: 392px;
  border-radius: 16px;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.12);
  background-color: rgba(246, 252, 255, 1);
  top: 17%;
  display: none;
}

.AlumniTest:hover .hoverBox {
  display: block;
}
.AlumniChildContainer {
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: baseline;
}

.AlumniHeading2 {
  color: #191919;
  font-size: 62px;
  font-family: Playfair Display;
  font-weight: 700;
  line-height: 71.8px;
  word-wrap: break-word;
  text-align: center;
}
.name2 {
  color: #191919;
  font-size: 20px;
  font-family: Playfair Display;
  font-weight: 600;
  word-wrap: break-word;
  z-index: 1;
}
.desig2 {
  color: #5a5a5a;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  text-transform: uppercase;
  word-wrap: break-word;
  z-index: 1;
  text-align: center;
}
.testimony2 {
  color: #4a4a4a;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 300;
  word-wrap: break-word;
  width: 270px;
  z-index: 1;
  text-align: center;
}
.MobFlex {
  display: none;
}

.SubscribeContainer {
  padding: 5rem calc(50vw - 35rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(43, 48, 106, 1);
  gap: 2rem;
}

.SubsHeading {
  color: white;
  text-align: center;
  font-size: 42px;
  font-family: Playfair Display;
  font-weight: 700;
  line-height: 57.41px;
  word-wrap: break-word;
}
.InputSection {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.NotePara {
  color: white;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 300;
  line-height: 19.14px;
  word-wrap: break-word;
  text-align: center;
}
.MobCrsl {
  display: none;
}
.dashedBox {
  border-top: 0.1rem dashed #3481e8;
  border-bottom: 0.1rem dashed #3481e8;
  width: fit-content;
  padding: 1rem 0;
}
.disabled {
  cursor: not-allowed !important;
}

@media (max-width: 767px) {
  .AssocHeroContainer {
    img {
      width: 95vw;
      height: 200px;
    }
  }
  .MobCrsl {
    display: block;
    width: 95vw;
  }
  .carousel-fade .carousel-item {
    transition-property: none !important;
  }

  .AssociationHeroHeading {
    font-family: Playfair Display;
    font-size: 42px;
    font-weight: 300;
    line-height: 42px;
    position: absolute;
    color: white;
    top: 130px;
    width: 100%;
    text-align: center;
  }
  .AssociationHeroPara {
    font-family: "Poppins", sans-serif;
    line-height: 22px;
    position: absolute;
    color: white;
    top: 215px;
    width: 100%;
    text-align: center;
  }
  .TopTextContainer {
    flex-direction: column;
    padding: 0.5rem;
  }
  .line {
    display: none;
  }
  .SupportContainer {
    flex-direction: column;
    padding: 1rem;
  }
  .SuppImg {
    width: 95vw;
  }
  .AlumniFrameContainer {
    flex-direction: column;
    padding: 0rem;
    gap: 1rem;
  }
  .FrameImg {
    width: 95vw;
  }
  .GroupContainer {
    flex-direction: column;
    gap: 1rem;
  }
  .GrpImg {
    width: 95vw !important;
  }
  .AlumniChildContainer {
    display: none;
  }
  .MobFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 422px;
  }
  .InputSection {
    flex-direction: column;
  }
  .SubscribeContainer {
    padding: 1rem;
    align-items: center;
  }
  .eventsMarginCard {
    flex-direction: column;
  }
  .AlumniFrameElements {
    padding: 1rem;
  }
  .CardConatinerCalender {
    padding: 1rem 5rem;
  }
  .CalenderCardText {
    padding: 2rem;
  }
}
