.threeStepContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 6rem calc(50vw - 35rem);
  gap: 2rem;
  background-color: rgba(249, 249, 249, 1);
}

.threeStepImg {
  width: 899px;
  height: 82px;
}
.childThreeStep {
  display: flex;
  gap: 2rem;
}

.grandChildThreeStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.headingThreeStep {
  font-family: Playfair Display;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 23.75rem;
}
.subheadingThreeStep {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.seperateThreeImg {
  height: 82px;
  width: 82px;
  display: none;
}
.donationCauseContainer {
  display: flex;
  flex-direction: column;
  padding: 5rem;
  align-items: center;
  gap: 3rem;
}
.donationCardContainer {
  display: flex;
  gap: 2rem;
}

.donationCard {
  display: flex;
  flex-direction: column;
  width: 352px;
  height: 442px;
  box-shadow: 0px 4px 24px 12px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  gap: 1rem;
}
.donationCard:hover {
  background-color: rgba(43, 48, 106, 1);
  color: white;
}
.cardImgDonate {
  width: 352px;
  height: 220px;
  border-radius: 16px 16px 0px 0px;
  object-fit: cover;
}
.cardText1 {
  font-family: Playfair Display;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1rem;
  height: 58px;
  justify-content: space-evenly;
}
.cardText2 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1rem;
}
.donateInfoContainer {
  display: flex;
  flex-direction: column;
  background-color: rgba(218, 242, 255, 1);
  align-items: center;
  padding: 5rem;
  gap: 1rem;
}
.donateSubHeading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.donationBoxContainer {
  display: flex;
  gap: 2rem;
}
.donationBox {
  border-radius: 16px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 542px;
  height: 352px;
  background-color: rgba(255, 255, 255, 1);
}
.donationBoxItem1 {
  font-family: Playfair Display;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}
.donationBoxItem2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(48, 48, 48, 1);
}
.donationBoxItem3 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(118, 118, 118, 1);
}
.donationBoxItem4 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(43, 48, 106, 1);
}
.ackFormConatiner {
  display: flex;
  flex-direction: column;
  padding: 5rem;
  align-items: center;
  width: 100%;
  gap: 2rem;
  select {
    width: calc(50% - 1rem);
    border: 1px solid rgb(238, 238, 238);
    color: rgba(160, 160, 160, 1);
    padding-left: 0.5rem;
    font-size: 1.25rem;
  }
}
.formContaineDonatePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(249, 249, 249, 1);
  width: 1120px;
  gap: 1rem;
  padding-bottom: 4rem;
}
.donationDetails {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(160, 160, 160, 1);
}
.mobDonateButton {
  display: none;
}
.deskDonateButton {
  display: block;
}
.ContributionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}

.quoteImg {
  display: flex;
  width: 100%;
}

.contributionText {
  position: absolute;
  display: flex;
  width: 790px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  font-family: Playfair Display;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.section80 {
  display: flex;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  background-color: rgba(52, 129, 232, 1);
  color: rgba(255, 255, 255, 1);
  width: 100%;
  justify-content: center;
  height: 63px;
  align-items: center;
}
.FaqContainer {
  padding: 5rem calc(50vw - 35rem);
}

.styles_faq-row-wrapper__3vA1D h2 {
  width: 100% !important;
  text-align: center !important;
  font-family: Playfair Display;
  font-size: 42px;
  font-weight: 800;
  line-height: 59px;
  letter-spacing: -0.02em;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.faqText {
  text-align: center;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw
  svg {
  content: url("../assets/donatePage/minus.svg") !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  content: url("../assets/donatePage/plus.svg") !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  content: url("../assets/donatePage/plus.svg") !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY.styles_expanded__3elPy
  .styles_icon-wrapper__2cftw {
  content: url("../assets/donatePage/minus.svg") !important;
  transform: rotate(0);
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 95%;
  border: 1px solid rgb(238, 238, 238);
  background: #ffffff;
  opacity: 75%;
  cursor: pointer;
}

.upload-btn-wrapper-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background: #ffffff;
  padding: 8px 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 3.5rem;
  color: #4d4949;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 163.5%; /* 1.83938rem */
  opacity: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.contactformError {
  color: red;
}

@media (max-width: 767px) {
  .seperateThreeImg {
    display: block;
  }
  .ackFormConatiner {
    padding: 1rem;
  }
  .threeStepImg {
    display: none;
  }
  .childThreeStep {
    flex-direction: column;
  }
  .threeStepContainer {
    padding: 1rem;
  }
  .donationCardContainer {
    flex-direction: column;
  }
  .donationBoxContainer {
    flex-direction: column;
    padding: 1rem;
  }
  .donationBox {
    width: 100%;
    padding: 1rem;
  }
  .quoteImg {
    height: 116px;
    object-fit: cover;
  }
  .donateInfoContainer {
    padding: 1rem;
  }
  .formContaineDonatePage {
    width: 100%;
  }
  .mobDonateButton {
    display: block;
  }
  .deskDonateButton {
    display: none;
  }
  .contributionText {
    line-height: normal;
    font-size: medium;
    padding: 1rem;
    width: 100% !important;
    gap: 1rem;
    position: absolute !important;
    top: 17% !important;
  }
  .FaqContainer {
    padding: 1rem;
  }
  .contributiontTop {
    top: auto !important;
  }
}
