.purposeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  gap: 2rem;
  padding-bottom: 0px;
}
.purposeContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  gap: 2rem;
  padding-top: 1rem;
}
.constructionFilter {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
  align-items: center;
  gap: 2rem;
}

.constructionChips {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.newBuildingCardContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.videoPlayer {
  position: absolute;
  z-index: 3;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  left: 0px;
  background: #000;
}
.headingTextNewbuilding {
  font-family: Playfair Display;
  font-size: 62px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: 0em;
  text-align: center;
}
.headingSubTextNewbuilding {
  font-family: Playfair Display;
  font-size: 2.625rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}
.vtHeadingContainer {
  min-width: 30%;
  display: flex;
  gap: 4rem;
  flex-direction: column;
}
.carouselItem {
  width: 22.25rem;
  height: 15rem;
  video {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background-color: #fff;
  }
}
.purposeChildren {
  padding: 5rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.purposeText {
  width: 475px;
}
.projectCon {
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.purposeImg {
  width: 627;
  height: 418px;
  border-radius: 16px;
}
.imgConstruction {
  width: 614px;
  height: 402px;
}

.slick-next:before {
  content: url("../assets/associationpage/rightArrow.svg");
  z-index: 1;
  position: relative;
  left: -81px;
}

.slick-prev::before {
  content: url("../assets/associationpage/leftArrow.svg");
}
.slick-slide img {
  display: block;
  width: 374px;
  height: auto;
}
.slick-arrow {
  z-index: 1;
}
.slick-prev {
  z-index: 1;
  content: none;
}
.ConstructionContainer {
  display: flex;
  flex-direction: column;
}
.vtContainer {
  padding: 5rem 0 5rem 10rem;
  width: 100%;
  overflow: hidden;
  background: rgba(218, 242, 255, 1);
  position: relative;
  display: flex;
  gap: 2rem;
}
.carousel {
  height: 100%;
  display: flex;
  gap: 1rem;
}
.projectPlanContainer {
  padding: 5rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.projectEle {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.projectImg {
  width: 543px;
  height: 421px;
}

@media (max-width: 767px) {
  .purposeChildren {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }
  .purposeImg {
    object-fit: fill;
    width: 100%;
    height: auto;
  }
  .slick-next:before {
    left: -32px;
  }
  .purposeContainer {
    padding: 2rem;
  }
  .vtImg {
    width: 100% !important;
  }
  .projectPlanContainer {
    flex-direction: column;
    padding: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .projectImg {
    width: 100%;
  }
  .vtContainer {
    padding: 1rem;
  }
  .purposeText {
    width: 100%;
  }
  .purposeContainer2 {
    padding: 1rem;
    padding-left: 0px;
  }
  .headingTextNewbuilding {
    font-size: xx-large;
    line-height: normal;
  }

  .newBuildingCardContainer {
    flex-direction: column;
    width: 100%;
  }
}
