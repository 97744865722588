.heroBanner {
  background-image: url("../assets/school_history_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  height: 500px;
  width: 100%;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.heroBannerTitle {
  color: white;
  font-size: 80px;
  font-weight: bold;
}
.heroBannerDescription {
  color: white;
  font-size: 28px;
  text-align: center;
}

@media (max-width: 767px) {
  .eventsBody {
    display: flex;
    /* padding: 50px 200px 100px 200px; */
    padding: 5px;
    width: 100%;
    flex-wrap: wrap;
  }

  .heroBannerDescription {
    color: white;
    font-size: 28px;
    max-width: 90%;
    text-align: center;
  }
  .heroBannerTitle {
    padding: 27px 30px;
    font-size: 60px;
  }
}

.founderDivbodyContainer {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.componentBodyContainer {
  display: flex;
  padding: 50px;
  justify-content: center;
  align-items: center;
}

.founderInfoSection {
  padding: 25px;
  margin: 15px 15px 15px 100px;
}

.imageDiv {
  background-color: rgb(245, 245, 245);
  max-width: 450px;
  max-height: 450px;
  border-radius: 50%;
  img {
    object-fit: cover;
  }
}
.contentContainer {
  margin-bottom: 50px;
}
.founderTitleDiv {
  color: #191919;
  font-family: "Playfair Display";
  font-size: 3.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 115.8%; /* 4.48725rem */
}
.commentBlock {
  width: 100%;
  color: #4a4a4a;
  margin-top: 25px;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.founderNavblock {
  width: 20%;
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #f54e39;
  cursor: pointer;
}

.rotateClass {
  transform: rotate(180deg);
}

.disableClick {
  pointer-events: none;
}

.presidentDivBodyContainer {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin: 50px 0;
}

.presidentDivTitle {
  color: #191919;
  text-align: center;
  font-family: Playfair Display;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 115.8%; /* 48.636px */
  margin: 20px;
}
.presidentDivDes {
  color: #404040;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 60rem;
  margin-top: 10px;
}

.presidentProfile {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  padding: 10px;
  flex-wrap: wrap;
}

.secetaryDivBodyContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin: 50px 0;
}

.spaceClass {
  margin-top: 40px;
}
.pointer {
  cursor: pointer;
}

@media (max-width: 727px) {
  .founderNavblock {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .buttonBlock {
    margin: 10px;
  }
  .bodyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .founderDivbodyContainer {
    display: flex;
    flex-direction: column;
  }
  .componentBodyContainer {
    flex-direction: column;
    padding: 0;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
  }
  .founderInfoSection {
    padding: 10px;
    margin: 0;
  }
  .founderTitleDiv {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media only screen and (max-width: 500px) {
  .founderNavblock {
    width: 15%;
  }

  .imageDiv {
    img {
      width: 18em;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .founderNavblock {
    width: 20%;
  }
}

@media only screen and (min-width: 1800px) {
  .founderNavblock {
    width: 18%;
  }

  .founderInfoSection {
    max-width: 30%;
  }
}
