.headerDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    height: 123px;
    justify-content: space-around;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
    padding: 10px;    

}

.buttonDiv {
    cursor: pointer;
    
}
.headerOptions{
    display: flex;
    justify-content: space-between;
    width: 1100px;
    align-items: center;
}

.buttonDiv img{

}
.logoHome{
    width: 82px;
    height: 96px;
}

.headerDivMob{
    display: none;
    background-color: #ffffff;

  
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.mob{
    display: none;
   
}

.LogoImg{
    display: none;
}
.borderBottom{
    border-bottom: 2px solid red;
}
@media (max-width: 1044px) {
    .headerDiv{
        display: none;
    }
    .mob{
        display: block;
        position:absolute;
        right:12px;
        top:25px;
    }
    .headerDivMob{
        gap: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1000;
        width: 96vw;
        margin-left: .5rem;
        top: 7.5rem;

    }
    .LogoImg{
        display: block;
        position: relative;
        margin-bottom: .5rem;
        width: 82px;
        height: 96px;
        margin: 1rem;
    }
   
}

@media (max-width: 727px) {
    .borderBottom{
        border-bottom: none !important;
    }
}