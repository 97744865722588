.eventsContainer {
  width: 100%;
}
.eventsBanner {
  background-image: url("../assets/events_banner.svg") !important;
}
.heroBanner {
  background-image: url("../assets/Mask_Group.svg");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  height: 500px;
  width: 100%;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.heroBannerTitle {
  color: white;
  font-size: 80px;
  font-weight: bold;
  font-family: "Playfair Display";
}

.heroBannerDescription {
  color: white;
  font-size: 28px;
  max-width: 45%;
  text-align: center;
}

.eventsFilterDiv {
  display: flex;
  padding: 20px 30px 0 30px;
  flex-wrap: wrap;
  margin: 10px 0 20px 0;
  align-items: center;
  justify-content: center;
}

.eventsBody {
  display: flex;
  padding: 35px calc(50vw - 35rem) 0 calc(50vw - 35rem);
  flex-wrap: wrap;
  gap: 2.25rem;
  justify-content: center;
}
.eventsCard {
  border-radius: 20px;
  width: 33.875rem;
  height: 25rem;
  box-shadow: 1px 2px 5px #d9d9d9;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  cursor: pointer;
}

.eventsCardNewBuilding {
  border-radius: 20px;
  width: 379.889px;
  height: 300px;
  box-shadow: 1px 2px 5px #d9d9d9;
  border: 1px solid #d9d9d9;
  margin: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.eventsCardImgDiv {
  height: 70%;
  margin-bottom: 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 20px 20px 0 0;
  }
}

.landscape {
  height: 30px;
  width: 80px;
}

.eventsCardContentDiv {
  padding: 5px 20px;
}

.newBuildingHeadingContainer {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  gap: 6px;
}
.NewBuildingCardSubtitle {
  color: #4a4a4a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.eventsCardTitleDiv {
  width: 100%;
  font-size: 24px;
  color: #191919;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.NewBuildingCardtitle {
  color: #191919;
  font-family: Playfair Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.eventsCardDateDiv {
  font-size: 14px;
  color: #4a4a4a;
  display: flex;
  margin-top: 5px;

  span {
    padding: 0 6px;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

//responsive

@media (max-width: 767px) {
  .eventsBody {
    display: flex;
    /* padding: 50px 200px 100px 200px; */
    padding: 5px;
    width: 100%;
    flex-wrap: wrap;
  }
  .eventsCardNewBuilding {
    width: 100% !important;
  }
  .heroBannerDescription {
    color: white;
    font-size: 28px;
    max-width: 90%;
    text-align: center;
  }
  .heroBannerTitle {
    padding: 27px 30px;
    font-size: 60px;
  }
  .eventsCard {
    width: 100%;
  }

  .eventsCardImgDiv {
    width: 100%;
  }
}
