.eventModalHeader {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 20px 20px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
}
.modalDescriptionDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-family: Playfair Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modalDescriptionSub {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.moadalDescriptionFooter {
  color: #e9e9e9;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.constructionDivContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.eventMiddleDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.eventMiddleNextDiv {
  width: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding: 10px;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
  svg {
    fill: fff !important;
  }
}

.disableEventMiddleNextDiv {
  pointer-events: none;
}

.eventMiddleImgDiv {
  max-width: 45rem;
  padding: 15px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 40rem;
    border-radius: 1rem;
  }
}

.eventMiddleImgDivData {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rotateClass {
  transform: rotate(180deg);
}
@media (max-width: 767px) {
  .ReactModal__Content--after-open {
    inset: 0 !important;
    padding: 0 !important;
  }

  .eventMiddleImgDiv img {
    width: 100%;
  }
  .eventMiddleImgDiv {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .eventMiddleImgDivData {
    color: white;
  }
}
