.thankyouHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}
.thankyouText{
    width: 600px;

}
.thankyouCardContainer{
  display: flex;
  gap: 32px;
}

.thankyouHeaderChild {
  display: flex;
  flex-direction: column;
}
.headerThankyouImg {
  height: 334px;
  width: 622px;
}
.thankyouHeroContainer {
  display: flex;
  position: relative;
}
.thankyouHero {
  width: 100%;
  height: 499px;
}
.thankyouHeroElem {
  display: flex;
  align-items: center;
  position: absolute;
  padding-top: 5rem;
  width: 100%;
  justify-content: center;
  gap: 14rem;
}
.thankyouHeroElemChild {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.thankyouHeroElemBox {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  width: 383px;
  height: 355px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1)
}
.heroHeader{
    font-family: Playfair Display;
font-size: 36px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
width: 623px;

}
.contribution{
    font-family: Poppins;
font-size: 24px;
font-weight: 500;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.listContribution{
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.boxheaderHeroText{
    font-family: Playfair Display;
font-size: 32px;
font-weight: 600;
line-height: 43px;
letter-spacing: 0em;
text-align: center;

}
.circleImg{
    position: absolute;
    width: 383px;
    height: 320px;
    z-index: 0;
}
.socialIcons{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}
.wishContainer{
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.wishChildEle{
    display: flex;
}
.wishBox{
    padding: 1.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 352px;
    height: 338px;
    border-radius: 16px;
    background-color: rgba(218, 242, 255, 1);
}
.wishBoxE1{
    font-family: Playfair Display;
font-size: 24px;
font-weight: 600;
line-height: 32px;
letter-spacing: 0em;
text-align: left;

}
.wishBoxE2{
    font-family: Poppins;
    cursor: pointer;

letter-spacing: 0em;
text-align: left;

}
.wishBoxE3{
    font-family: Poppins;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: center;

}
@media (max-width: 767px) {
  .thankyouHeaderContainer {
    flex-direction: column;
    gap: 1rem;
  }
  .thankyouText{
    width: 95vw;
    text-align: center;
  }
  .headerThankyouImg {
    width: 95vw;
    height: auto;
  }
  .thankyouHeroContainer{
    height: 750px;
  }
  .thankyouHeroElem{
    flex-direction: column;
    gap: 1rem;
  }
  .heroHeader{
    text-align: center;
    line-height: normal;
    font-size: 20px;
    width: 100%;

  }
  .thankyouCardContainer{
    flex-direction: column;
  }
  .contribution{
    text-align: center;
    line-height: normal;
    font-size: 20px;
    width: 100%;

  }
  .listContribution{
    line-height: normal;
    font-size: 20px;
    width: 100%;

  }
}
