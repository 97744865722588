.academicsHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  padding: 5rem;
  height: 412px;
}
.widthPara {
  width: 504px;
}

.academicsHeading {
  font-size: 82px;
  line-height: 115.8%;
  font-family: "Playfair Display";
  color: #191919;
  text-align: center;
}
.theStudentsPara {
  position: relative;
  font-size: 28px;
  font-family: Poppins;
  color: #4a4a4a;
  text-align: center;
  display: inline-block;
  width: 853px;
}

.academicsInfoContainer {
  display: flex;
  background-color: rgba(43, 48, 106, 1);
  justify-content: space-around;
  width: 1120px;
  padding: 12px 0px 12px 0px;
  border-radius: 16px;
  position: absolute;
  top: 483px;
}
.VectorLogo {
  height: 24px !important;
  width: 24px !important;
  margin-right: 12px;
}
.academicsInfo {
  font-family: "Poppins";
  font-size: 42px;
  font-weight: 600;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.academicsInfoSub {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.academicProgramsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 1);
  padding: 5rem;
  gap: 4rem;
  padding-top: 9rem;
}

.acadmeicsChild {
  display: flex;
  gap: 2rem;
  align-items: center;
  img {
    width: 630px;
    height: auto;
    border-radius: 12px;
  }
}
.academicsGrandChild {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 461px;
  justify-content: center;
}
.academicsFlex {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.headingCardAcademics {
  font-family: Playfair Display;
  font-size: 42px;
  font-weight: 600;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
}
.paraCardAcademics {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.StudentWondersContainer {
  display: flex;
  padding: 5rem calc(50vw - 35rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(218, 242, 255, 1);
}
.studentInfo {
  display: flex;
  width: 80%;
  align-items: flex-start;
  gap: 5rem;
  justify-content: space-between;
  img {
    width: 268px;
    height: 268px;
    border-radius: 50%;
    border: 1rem solid #f5f5f58a;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 1rem;
  }
}

.studentFlexInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}
.randrContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 5rem calc(50vw - 35rem);
  gap: 5rem;
}
.paraContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  div {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.rPara {
  display: flex;
  gap: 2rem;
}
.studenWondersCarousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  width: 100%;
  .icon {
    margin-bottom: 10rem;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .academicsInfoContainer {
    flex-direction: column;
    width: 95vw;
    gap: 1rem;
    padding: 1rem;
    position: static;
  }
  .widthPara {
    width: 100%;
  }
  .theStudentsPara {
    width: 95vw;
    text-align: center;
  }
  .academicsHeading {
    width: 95vw;
    font-size: 40px;
  }
  .acadmeicsChild img {
    width: 95vw;
  }
  .acadmeicsChild {
    flex-direction: column;
  }
  .studentInfo {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .paraContainer {
    flex-direction: column;
    width: 95vw;
  }
  .randrContainer {
    padding: 0px;
    padding-top: 1rem;
  }
  .academicsHeader {
    height: auto;
    padding: 0;
  }
  .academicsInfo {
    text-align: center;
  }
  .academicsInfoSub {
    text-align: center;
  }
  .academicsGrandChild {
    width: 100%;
  }
  .rPara {
    flex-direction: column;
  }
}
