.filterChipsContainer {

    background: #F4F4F4;
    padding: 10px;
    border-radius: 25px;
    margin: 10px 10px;
    border:1px solid #D0D0D0;
    cursor: pointer;
    color: #4E4E4F;
    font-weight: 500;
    min-width: 80px;
    display: flex;
    justify-content: center;

    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.filterChipsSelected {
    background: #3481E8;
    color: #ffffff;
    border: 0px;
}