.heroLifeContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.eventsTextStyle {
  padding-left: calc(50vw - 35rem);
}

.dbtrHeroImg {
  width: 100%;
}
.apjText {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
.apjContainer {
  width: 100%;
  background-color: rgba(218, 242, 255, 1);
  padding-top: 12rem;
  padding-bottom: 5rem;
  height: 398px;
}

.reactK {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 90px;
  background-color: rgba(218, 242, 255, 1);
  width: 669px;
  height: 419px;
}

.reactKtext {
  font-family: Poppins;

  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 4rem;
}
.studentRect {
  font-family: Playfair Display;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.reactKImg {
  position: absolute !important;
  width: 683px !important;
}
.graduation {
  width: 100%;
}
.graduation input {
  width: 100%;
}
.browse {
  border-radius: 0.25rem;
  border: 1px solid #949494;
  color: #949494;
  font-family: Poppins;
  font-size: 1.25rem;
}
.uploadContainer {
  border: 1px dashed #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3rem;
  width: 100%;
  color: #a1a1a1;
  font-size: 1.25rem;
}
.kathaigal {
  padding: 6rem calc(50vw - 35rem);
}

@media (max-width: 767px) {
  .reactKImg {
    display: none;
  }
  .reactK {
    height: auto;
    width: 100%;
    border-radius: 0px;
  }
  .reactKtext {
    padding: 1.7rem;
  }
  .eventsTextStyle {
    padding-left: 0px;
  }
}
