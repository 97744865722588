.moreButtonContainer{
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    font-weight: 500;
    font-size: 20px;  
}

.moreButtonOutline{ 
    border: 2px solid #F54E39;
    color: #F54E39;
    border-radius: 4px;
}
.moreButtonFilled {
    background-color: #F54E39;
    color: #ffff;
    border-radius: 8px;
}

.AlumniSectionConatianerWhite{
    
    background-color: rgba(249, 249, 249, 1);
    width: 100%;
   

}
.AlumniSectionConatianerBlue{
    
   background-color: rgba(218, 242, 255, 1);
   width: 100%;
   

}
.bgcolor{
    background-color: rgba(43, 48, 106, 1) !important;
}
.textcolor{
    color: rgba(255, 255, 255, 1) !important;
}

.widthHeroText{
    width : 801px !important;
}

@media (max-width: 767px) {
   .height{
    height: 240px !important;
   }
}
