.missionParentContainer {
  display: flex;
  flex-direction: column;
  background-color: rgb(249, 249, 249);
  padding: 5rem;
  gap: 4rem;
}

.extraContent p {
  color: #404040;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.missionContainer {
  display: flex;
  justify-content: center;
  gap: 4rem;
}
.missionEle {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  max-width: 529px;
}
.missionImg {
  width: 519px;
  height: 309px;
}
.focusContainer {
  display: flex;
  justify-content: center;
  padding: 5rem 10rem;
  gap: 4rem;
  align-items: center;
}
.focusContainer2 {
  display: flex;
  justify-content: center;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  gap: 2rem;
}

.focusHeading {
  font-family: Playfair Display;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
}
.messageTrusteeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem calc(50vw - 35rem);
  gap: 3rem;
}
.maintrustee {
  display: flex;
  gap: 4.5rem;
}
.trusteeImg {
  width: 309px;
  height: 354px;
}
.trusteeText {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.trusteeMain {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.extraContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.uppArrowIcon {
  transform: rotate(180deg);
}
.focusArea {
  padding-top: 3rem;
}
.titleClass {
  font-size: 5.125rem;
}
.paraClass {
  font-size: 1.75rem;
}
@media (max-width: 767px) {
  .missionContainer {
    flex-direction: column;
    padding: 1rem;
  }
  .focusContainer {
    flex-direction: column;
    padding: 1rem;
  }
  .focusContainer2 {
    flex-direction: column;
    padding: 1rem;
  }
  .missionEle {
    flex-direction: column;
    padding: 1rem;
  }
  .missionImg {
    width: 100%;
    padding: 1rem;
  }
  .maintrustee {
    flex-direction: column;
  }
  .messageTrusteeContainer {
    padding: 1rem;
  }
  .trusteeImg {
    width: 100%;
    padding: 1rem;
  }
  .trusteeText {
    padding: 1rem;
  }
  .missionParentContainer {
    padding: 1rem;
    gap: 0rem;
  }
}
